<template>
  <Backoffice :loginRequired="false">
    <div class="container">
      <div class="login__form-wrapper">
        <div class="login__column login__column--first">
          <div class="logo">
            <img
              src="@/assets/thomson-reuters-logo-white.png"
              alt="Dashboard | Thomson Reuters"
            />
          </div>
        </div>
        <div class="login__column login__column--second">
          <form
            @submit.prevent="forgot"
            class="auth-form"
            v-if="!showSuccessMessage"
          >
            <h1>Nova senha</h1>
            <div class="field required" :class="{ error: $v.form.email.$error }">
              <div class="label">Email</div>
              <input
                type="email"
                tabindex="1"
                v-model.trim="$v.form.email.$model"
              />
            </div>
            <div
              v-if="showErrorMessage"
              class="error__message ui red horizontal label"
            >
              <p>Por favor, digite um email válido</p>
            </div>
            <div class="actions">
              <button class="ui button cancel" @click="cancel()" tabindex="4">
                Cancelar
              </button>
              <button class="ui button" type="submit" tabindex="3">
                Enviar
              </button>
            </div>
          </form>
          <div
            class="success__message-wrapper actions animated fadeInUp"
            v-show="showSuccessMessage"
          >
            <h2 class="success__message">
              Um email com um link de recuperação de senha vou enviado para o seu email.
            </h2>
            <button class="cancel" @click="cancel()">
              Voltar para a página de login
            </button>
          </div>
        </div>
      </div>
    </div>
  </Backoffice>
</template>

<script>
import Backoffice from "../../../Template/Backoffice";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "ForgotPassword",
  components: {
    Backoffice
  },
  props: {},
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      showSuccessMessage: false,
      showErrorMessage: false,
      form: {
        email: ""
      }
    };
  },
  methods: {
    forgot() {
      let vm = this;
      if (!vm.$v.$invalid) {
        vm.loading = true;
        vm.showErrorMessage = false;
        vm.$store
          .dispatch("Register/FORGOT_PASSWORD", vm.form)
          .then(_response => {
            vm.showSuccessMessage = true;
          })
          .catch(_error => {
            vm.showErrorMessage = true;
          })
          .finally(() => {
            vm.loading = false;
          });
      } else {
        vm.showErrorMessage = true;
      }
    },
    cancel() {
      this.$router.push({ name: "login" });
    }
  },
  mounted() {
    localStorage.removeItem("data");
    localStorage.removeItem("token");
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/globals/colors';
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: $color-gray-light;
}

.login__form-wrapper {
  display: flex;
  background-color: $color-white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0,0,0,.1);
  max-width: 610px;
  width: 80%;
  overflow: hidden;
}

.login__column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 50%;
  &--first {
    background-color: $color-secondary;
    padding-left: 50px;
    padding-right: 50px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('~@/assets/bg-thomson-reuters-office.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      mix-blend-mode: multiply;
      z-index: 0;
    }
  }
}

.logo {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  img {
    width: 200px;
  }
}

.auth-form {
  position: relative;
  h1 {
    display: block;
    color:$color-primary;
    text-align: center;
    text-decoration: none;
    margin: 0 0 30px;
  }
  .field {
    width: 268px;
    margin-bottom: 30px;
    border-bottom: 1px solid $color-primary;
    box-sizing: border-box;
    .label {
      color: $color-primary;
      opacity: 0.8;
      font-size: 0.9rem;
      font-family: "Barlow";
      margin-top: 10px;
    }
    input {
      background-color: transparent;
      color: $color-primary;
      width: 100%;
      height: 30px;
      margin: 5px 0;
      font-size: 1rem;
      line-height: 30px;
      border: none;
      outline: none;
    }
  }
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 45%;
    display: inline-block;
    height: 36px;
    border: none;
    margin: 10px 0;
    border-radius: 8px;
    background-color: $color-primary;
    color: $color-white;
    font-family: "Barlow";
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: 400ms;
    &:hover,
    &:focus {
      background-color: $color-primary;
      color: $color-white;
      opacity: 0.8;
    }
    &.cancel {
      background-color: $color-white;
      color: $color-primary;
    }
  }
}
.success__message-wrapper {
  flex-direction: column;
  align-items: center;
  .success__message {
    color: $color-primary;
    font-size: 1.5rem;
    text-align: center;
  }
}
.error__message.ui.horizontal.label {
  width: 100%;
  margin: 0;
  font-weight: lighter;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@media screen and (max-width: 768px) {
  .login__form-wrapper {
    max-width: 80%;
  }
  .login__column {
    width: 100%;
    &--first {
      display: none;
    }
  }
}
</style>
